import { connect, useDispatch } from 'react-redux';
import { useState } from 'react';
import { showModal } from '../../state/actions/buyMoreCreditsActions';
import subscriptionHelpers from '../../helpers/subscriptionHelpers';
import creditsHelpers from '../../helpers/creditsHelpers';

import { ApplicationState } from '../../types/state/storeTypes';
import { SubscriptionDetail } from '../../types/api/SubscriptionTypes';
import {
  RemainingCreditsIndicatorProps,
  RemainingCreditsIndicatorStateProps
} from '../../types/components/nav/RemainingCreditsIndicator';

import Image from 'next/image';
import { MainLinkButton } from '../styled/buttons/MainLinkButton';
import { QuestionIcon } from '../../modules/Icons';
import { Tooltip } from 'react-tooltip'

const RemainingCreditsIndicator: React.FC<RemainingCreditsIndicatorProps> = (
  props: RemainingCreditsIndicatorProps
) => {
  const { authenticated, subscription } = props;
  const { isCreditBasedLegacy, isAVFXPlusLegacy } = subscriptionHelpers;
  const { getNewCreditsDateTextFor } = creditsHelpers;
  const dispatch = useDispatch();

  if (!authenticated || !subscription) return <></>;

  const [popoverVisible, setPopoverVisible] = useState(false);

  const onBuyMoreCreditsClicked = () => {
    setPopoverVisible(false);
    dispatch(showModal('buy-more-credits'));
  };

  const getAvailableCredits = (): number => {
    if (isAVFXPlusLegacy(subscription)) return 0;
    if (isCreditBasedLegacy(subscription)) return subscription.legacy_current_credit_balance;
    return subscription.current_credit_balance;
  }

  const getCreditsRenewalText = (): string => {
    const renewalDateText = getNewCreditsDateTextFor(subscription);
    return `${subscription.plan_variant.recurring_credits} new credits on ${renewalDateText}`;
  };

  const onMouseEnter = () => {
    if (!subscription) return;
    setPopoverVisible(true);
  }

  const onMouseLeave = () => {
    setPopoverVisible(false);
  }

  const renderButton = (): React.ReactElement => {
    return (
      <button
        className="flex justify-center w-full h-full items-center md:mr-3 py-[5px] px-[20px] rounded-[5px] bg-a-dark-gray"
        cy-test-id="remaining-credits-indicator">
        <Image
          src="/img/pricing/credits-icon.png"
          width={24}
          height={24}
          alt="credits icon"
          className="h-fit"
        />
        <span
          className="text-white text-[14px] font-normal leading-[20px] ml-2"
          cy-test-id="remaining-credits-amount">
          {getAvailableCredits()}
        </span>
      </button>
    );
  };

  const renderPopover = (): React.ReactElement => {
    if (!subscription) return;
    return (
      <div
        className={`top-[49px] absolute right-[20px] md:right-[72px] ${
          popoverVisible ? 'block' : 'hidden'
        } pt-[21px]`}
        cy-test-id="remaining-credits-popover">
        <div className="p-4 w-[286px] bg-a-darker-gray rounded-[5px]">
          <span className="block text-white text-16 leading-24 font-normal">
            <Image
              src="/img/pricing/credits-icon.png"
              width={24}
              height={24}
              alt="credits icon"
              className="mr-2 inline-block"
            />
            You have <span className="font-bold">{getAvailableCredits()}</span>{' '}
            credits
          </span>
          <span className="block text-a-light-gray text-[14px] leading-22 font-normal inter mt-2">
            {getCreditsRenewalText()}
          </span>

          {!isCreditBasedLegacy(subscription) &&
            <MainLinkButton
              onClick={onBuyMoreCreditsClicked}
              variant="blue"
              className="w-full mt-4 uppercase !h-[40px]"
              testId="buy-more-credits-button">
              Buy credits
            </MainLinkButton>
          }

          {isCreditBasedLegacy(subscription) &&
            <div className="text-a-light-gray text-[12px] mt-4" cy-test-id="legacy-credit-info">
              Your plan uses legacy credits{' '}
              <QuestionIcon
                className="inline"
                data-tooltip-id="legacy-creds-tooltip"
                data-tooltip-content="Credits from legacy ActionVFX plans can only be used for 2D collections created by ActionVFX."
                data-tooltip-place="top"
              />
              <Tooltip id="legacy-creds-tooltip"
                style={{ backgroundColor: 'black', maxWidth: 260 }}
              />
            </div>
          }
        </div>
      </div>
    );
  };

  return (
    <div
      cy-test-id="remaining-credits-indicator-wrapper"
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}>
      {renderButton()}
      {renderPopover()}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState): RemainingCreditsIndicatorStateProps => ({
  authenticated: state.auth.authenticated,
  subscription: state.auth.subscription as SubscriptionDetail
});

export default connect(mapStateToProps)(RemainingCreditsIndicator);
