import { CreditPackConfig, CreditPurchaseVariant } from '../types/helpers/credits';
import { CreditPlanConfig, PlanVariantResponse, SubscriptionPlanVariantDetail } from '../types/api/SubscriptionTypes';
import {
  CreditPackResponse,
  PurchaseCreditPackPayload,
  PurchaseCustomCreditPackPayload,
} from '../types/api/CreditsTypes';
import { LicenseId, LicenseTypeDetail } from '../types/api/LicenseTypesTypes';
import { SubscriptionDetail } from '../types/api/SubscriptionTypes';
import { ProductResponse } from '../types/api/ProductsIndexTypes';
import { CollectionResponse } from '../types/api/CollectionsIndexTypes';
import { User } from '../types/api/UsersTypes';
import { GenericResponse, SuccessMessageResponse } from '../types/api/Http';
import { AxiosError } from 'axios';

import licenseHelpers from './licenseHelpers';
import axios from 'axios';

import { ENV } from '../constants/environments';

// TODO update model
const calculatePrice = (licenseTypes: Array<LicenseTypeDetail>, creditPack: CreditPackConfig, licenseId: LicenseId, customAmount?: number): number => {
  const { getLicenseDetailsById } = licenseHelpers;
  const amount = creditPack.amount || customAmount;
  switch (licenseId) {
    case 1:
    default:
      return creditPack.individual_price_per_credit * amount;
    case 2:
      return creditPack.business_price_per_credit * amount;
    case 3:
    case 4:
    case 5:
    case 6:
      const license = getLicenseDetailsById(licenseTypes, licenseId);
      return creditPack.business_price_per_credit * amount * license.price_factor;
  }
}

const calculatePricePerCredit = (licenseTypes: Array<LicenseTypeDetail>, creditPack: CreditPackConfig, licenseId: LicenseId): number => {
  const { getLicenseDetailsById } = licenseHelpers;
  switch (licenseId) {
    case 1:
    default:
      return creditPack.individual_price_per_credit;
    case 2:
      return creditPack.business_price_per_credit;
    case 3:
    case 4:
    case 5:
    case 6:
      const license = getLicenseDetailsById(licenseTypes, licenseId);
      return creditPack.business_price_per_credit * license.price_factor;
  }
}

const getFilteredCreditPacks = (creditsMissing: number, creditPacks: Array<CreditPackResponse>): Array<CreditPackResponse> => {
  return creditPacks
         .filter(
           pack => pack?.recurring_credits >= creditsMissing && pack?.recurring_credits > 0
         ).sort(
           (a, b) => a.recurring_credits - b.recurring_credits
         );
}

const getCustomCreditPackConfigFrom = (creditPacks: Array<CreditPackResponse>): CreditPackResponse => {
  return creditPacks.find(pack => pack.recurring_credits === 0);
}

const getFilteredCreditPlans = (creditsMissing: number, currentPlan: SubscriptionDetail, creditPlanVariants: Array<PlanVariantResponse>): Array<PlanVariantResponse> => {
  return creditPlanVariants.filter(
    planVariant => planVariant.recurring_credits >= creditsMissing &&
    planVariant.plan.name === currentPlan.license.type.name &&
    planVariant.interval === currentPlan.plan_variant.interval &&
    planVariant.recurring_credits > currentPlan.plan_variant.recurring_credits
  ).sort(
    (a, b) => a.recurring_credits - b.recurring_credits
  );
}

const calculatePlanPrice = (licenseTypes: Array<LicenseTypeDetail>, plan: any, licenseId: LicenseId): number => {
  const { getLicenseDetailsById } = licenseHelpers;
  switch (licenseId) {
    case 1:
    case 2:
    default:
      return plan.total_price;
    case 3:
    case 4:
    case 5:
    case 6:
      const license = getLicenseDetailsById(licenseTypes, licenseId);
      return plan.total_price * license.price_factor;
  }
};

const calculatePlanPricePerCredit = (licenseTypes: Array<LicenseTypeDetail>, plan: any, licenseId: LicenseId): number => {
  const { getLicenseDetailsById } = licenseHelpers;
  switch (licenseId) {
    case 1:
    case 2:
    default:
      return plan.credits_price;
    case 3:
    case 4:
    case 5:
    case 6:
      const license = getLicenseDetailsById(licenseTypes, licenseId);
      return plan.credits_price * license.price_factor;
  }
};

const generateBuyCreditsLayoutConfig = (creditsMissing: number, currentPlan: SubscriptionDetail, planVariants: Array<PlanVariantResponse>): Array<CreditPurchaseVariant> => {
  const filteredPlans = getFilteredCreditPlans(creditsMissing, currentPlan, planVariants);
  if (!filteredPlans.length) return ['creditPack'];
  switch (currentPlan.plan_variant.interval) {
    case 'month':
      return ['creditPlan', 'creditPack'];
    case 'year':
      if (creditsMissing <= 50)
        return ['creditPack', 'creditPlan']
      else
        return ['creditPlan', 'creditPack'];
    default:
      return [];
  };
};

const getCreditsPerIntervalTextFor = (plan: CreditPlanConfig | PlanVariantResponse | SubscriptionPlanVariantDetail): string => {
  const intervalShorthand = plan.interval === 'month' ? 'mo' : 'yr';
  return `${plan.recurring_credits} credits/${intervalShorthand}`;
}

const getNewCreditsDateTextFor = (subscription: SubscriptionDetail): string => {
  const interval = subscription.plan_variant.interval;

  const subscriptionCreatedDate = new Date(subscription.created_at);
  const createdDate = subscriptionCreatedDate.getDate();
  const createdMonth = subscriptionCreatedDate.getMonth();
  const createdYear = subscriptionCreatedDate.getFullYear();

  const today = new Date();

  let month: number = today.getMonth();
  let year: number = today.getFullYear();
  let monthName: string;
  let renewalDate: Date;

  switch (interval) {
    case 'month': {
      if (today.getDate() < createdDate) break;
      if (month < 11) {
        month += 1;
      } else {
        month = 0;
        year += 1;
      }
      break;
    }
    case 'year': {
      month = createdMonth;
      if (today.getMonth() > createdMonth) {
        year += 1;
        break;
      }
      if (today.getMonth() === createdMonth && today.getDate() >= createdDate) {
        year += 1;
        break;
      }
      if (
        today.getMonth() < createdMonth && today.getFullYear() > createdYear ||
        today.getMonth() === createdMonth && today.getDate() < createdDate
      ) {
        year = today.getFullYear();
        break;
      }
      break;
    }
    default:
      break;
  }

  renewalDate = new Date(year, month, createdDate);
  monthName = renewalDate.toLocaleString('default', { month: 'short' });
  return `${monthName} ${createdDate}, ${year}`;
}

const getCreditPriceTextFor = (product: CollectionResponse | ProductResponse | CreditPackResponse | SubscriptionPlanVariantDetail, customAmount?: number): string => {
  const recurring_credits = (product as CreditPackResponse | SubscriptionPlanVariantDetail).recurring_credits;
  const credit_price = (product as CollectionResponse | ProductResponse).credit_price;
  const price =
    customAmount || customAmount === 0 ? customAmount :
      recurring_credits || recurring_credits === 0 ? recurring_credits : credit_price
  const word = price === 1 ? 'credit' : 'credits';
  return `${price} ${word}`;
};

const purchaseCreditPack = async (
  creditPack: CreditPackResponse,
  payment_intent_id: string,
  user: User,
  customAmount: number | undefined,
  onError: Function,
): Promise<GenericResponse<SuccessMessageResponse> | AxiosError> => {
  const payload: PurchaseCreditPackPayload | PurchaseCustomCreditPackPayload = customAmount ?
  {
    is_custom: true,
    recurring_credits: customAmount,
    payment_intent: payment_intent_id,
  } :
  {
    credit_pack_variant_id: creditPack.id.toString(),
    payment_intent: payment_intent_id,
  };
  try {
    const result = await axios.post(`${ENV.api.baseURL}/purchases/credit-pack-variant`, payload, { headers: { authorization: user.token }});
    return result.data;
  } catch (e) {
    onError(e);
  }
}

const creditsHelpers = {
  getFilteredCreditPacks,
  calculatePrice,
  calculatePricePerCredit,
  getFilteredCreditPlans,
  calculatePlanPrice,
  calculatePlanPricePerCredit,
  generateBuyCreditsLayoutConfig,
  getCreditsPerIntervalTextFor,
  getNewCreditsDateTextFor,
  getCreditPriceTextFor,
  purchaseCreditPack,
  getCustomCreditPackConfigFrom,
};

export default creditsHelpers;
