import { User } from '../types/api/UsersTypes';

export const normalizeLicenseType = (mode: string): string => {
  const lowerType = mode.toLowerCase();
  if (lowerType === 'studio') return 'Enterprise';
  return mode.charAt(0).toUpperCase() + lowerType.slice(1);
};

export const checkFreeUser = (user: User | null | undefined): boolean => {
  if (!user) {
    return false;
  }

  if (user?.user_type === 'IndividualUser' || user?.user_type === 'BusinessUser' || user?.user_type as any === "undefined") {
    return true;
  }

  return false;
}
